@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/dt-base.scss';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 21px;
}

.return-button {
  margin-top: 35px;
}

.text-area {
  min-height: 457px;
}

.tabs {
  :global .tab-as-header__list {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

.vehicle-info-container {
  display: flex;
  flex-direction: column;
  line-height: 1.8rem;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: $dt-light-medium-gray;

  @include media($min-sm) {
    margin-right: auto;
    margin-left: 0;
    flex-direction: row;
    max-width: 600px;
  }
}

.vehicle-info-content {
  display: flex;
  text-align: left;
  font-weight: bold;
}

.vehicle-info-cell {
  text-align: left;
  font-weight: 700;
  width: 80px;
}

.vehicle-info-header-cell {
  text-align: left;
  font-weight: 400;
  width: 110px;
}

.vehicle-info-table {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  font-weight: 700;
  line-height: 1.8rem;
  font-size: 1rem;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;

  @include media($min-md) {
    border-bottom: 0;
    margin: 20px;
  }
}

.vehicle-info-table--bordered {
  border-bottom: 1px solid $dt-medium-light-gray;
  padding-bottom: 10px;

  @include media($min-md) {
    border-bottom: 0;
    padding-bottom: 0;
    border-right: 1px solid $dt-medium-light-gray;
  }
}

.vehicle-info-axle {
  color: $medium-gray;
  width: 80px;
  font-weight: bold;
}
